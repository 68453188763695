import BookIcon from "assets/images/svg/book.svg";
// import coupon from "assets/images/png/coupon.png";
import test from "assets/images/png/test_management.png";
import coupon from "assets/images/png/coupon.png";
export const menuItems = [
  { title: "Dashboard", icon: "dashboard", link: "/dashboard/home" },
  { title: "Manage Enquiries", icon: "blog", link: "/dashboard/enquires" },

  //{ title: "Bookings", icon: "settings", link: "/dashboard/manage-bookings" },
  {
    title: "Bookings",
    icon: "settings",
    link: "/dashboard/manage-bookings",
    subOptions: [
      { label: "Confirmed Booking", subLink: "/dashboard/manage-bookings/confirmed-booking" },
      { label: "Pending Booking", subLink: "/dashboard/manage-bookings/pending-booking" },
      { label: "Transactions", subLink: "/dashboard/manage-bookings/transactions" },
    ]
  },

  //{ title: "Payment Transaction", icon: "transaction", link: "/dashboard/paymenttransaction", },
  { title: "Users", icon: "blog", link: "/dashboard/users" },
  { title: "Send Report", image: BookIcon, link: "/dashboard/sendreports" },
  { title: "Blogs Management", icon: "blog", link: "/dashboard/blogs" },
  { title: "Category", icon: "settings", link: "/dashboard/category" },
  { title: "Newsletter", icon: "blog", link: "/dashboard/news-letter" },
  //{ title: "Report", image: BookIcon, link: "/dashboard/reports" },
  { title: "Diagnostic Management", image: test, link: "/dashboard/diagnosticmanagement", },
  { title: "Coupon Management", image: coupon, link: "/dashboard/couponmanagement", },
  { title: "Manage States", icon: "settings", link: "/dashboard/manage-states" },
  { title: "Manage Cities", icon: "settings", link: "/dashboard/manage-cities" },
  { title: "Manage Center Locations", icon: "settings", link: "/dashboard/manage-center-locations" },
  { title: "Settings", icon: "settings", link: "/dashboard/settings" },
  //{ title: "Manage Location", icon: "settings", link: "/dashboard/manage-location" },
];

export const paymentTransactionFilterData = [
  { label: "State", name: "stateId", options: [] },
  { label: "Location/Branch", name: "cityId", options: [] },
  {
    label: "Services",
    name: "type",
    options: [
      { label: "Package", value: "Package" },
      { label: "Test", value: "Test" },
    ],
    value: { label: "Package", value: "Package" },
  },
];

export const paymentTransactionGraphDateFilter = [
  { label: "1m", value: "1m" },
  { label: "2m", value: "2m" },
  { label: "3m", value: "3m" },
  { label: "YTD", value: "YTD" },
  { label: "1y", value: "1y" },
  { label: "All", value: "All" },
];

export const sendReportsFilterData = [
  { label: "State", name: "stateId", options: [] },
  { label: "Location/Branch", name: "cityId", options: [] },
];

export const transactionData = {
  headerDetails: [
    // { label: "S. No" },
    // { label: "User Name", align: "left" },
    // { label: "Packages/Test Name" },
    // { label: "Test Included" },
    // { label: "Appointment Date", align: "left" },
    // { label: "Amount" },
    // { label: "Status", align: "left" },
    // { label: "Payment Type", align: "left" },
    // { label: "Action" },

    { label: "S. No" },
    { label: "OrderID" },
    { label: "Customer", align: "left" },
    { label: "Payment Amount" },
    { label: "Payment Method" },
    { label: "Payment Status", align: "left" },
    { label: "Payment Date" },
    { label: "Transaction", align: "left" },
  ],
};

export const sendReportsData = {
  headerDetails: [
    { label: "S. No" },
    { label: "User Name", align: "left" },
    { label: "Test / Package Name", align: "left" },
    { label: "Test Included" },
    { label: "Date", align: "left" },
    { label: "Payment Details", align: "left" },
    { label: "Report Status", align: "left" },
    { label: "Payment Type", align: "left" },
    { label: "Status", align: "left" },
    { label: "Action", align: "left" },
  ],
};

export const ReportsData = {
  headerDetails: [
    { label: "S. No" },
    { label: "User Name", align: "left" },
    { label: "Test / Package Name", align: "left" },
    { label: "Test Included" },
    { label: "Date", align: "left" },
    { label: "Payment Details", align: "left" },
    { label: "Report Status", align: "left" },
    { label: "Payment Type", align: "left" },
    { label: "Status", align: "left" },
    { label: "Action", align: "left" },
  ],
};

export const testManagementData = {
  headerDetails: [
    { label: "S. No", align: "left" },
    { label: "Test Name", align: "left" },
    // { label: "Report Delivery" },
    { label: "Price", align: "left" },
    { label: "Category", align: "left" },
    { label: "Sub Category", align: "left" },
    // { label: "Center Locations",align:"left" },
    // { label: "Condition",align:"left" },
    { label: "Status", align: "left" },
    { label: "Action", width: "150px", align: "left" },
  ],
};
export const packageManagementData = {
  headerDetails: [
    { label: "S. No", align: "left" },
    { label: "Package Name", align: "left" },
    { label: "Tests Involved", align: "left" },
    { label: "Price", align: "left" },
    { label: "Status", align: "left" },
    { label: "Action" },
  ],
};

export const categoryManagementData = {
  headerDetails: [
    { label: "S. No" },
    { label: "Category", align: "left" },
    { label: "Status", align: "left" },
    { label: "Action", align: "left" },
  ],
};
export const subCategoryManagementData = {
  headerDetails: [
    { label: "S. No" },
    { label: "Category", align: "left" },
    { label: "Sub Category", align: "left" },
    { label: "Status", align: "left" },
    { label: "Action", align: "left" },
  ],
};
export const conditionManagementData = {
  headerDetails: [
    { label: "S. No" },
    { label: "Condition Name", align: "left" },
    { label: "Status", align: "left" },
    { label: "Action", align: "left" },
  ],
};
export const couponManagementData = {
  headerDetails: [
    { label: "S. No" },
    { label: "Coupon/Offer Name", align: "left" },
    { label: "Valid From", align: "left" },
    { label: "Valid To", align: "left" },
    { label: "Discount ", align: "left" },
    { label: "Status", align: "left" },
    { label: "Action", width: "150px", align: "left" },
  ],
};
export const categoryData = {
  headerDetails: [
    { label: "S. No" },
    { label: "Category", align: "left" },
    { label: "Action", align: "left" },
  ],
};

export const stateData = {
  headerDetails: [
    { label: "S. No" },
    { label: "Slug", align: "left" },
    { label: "Name", align: "left" },
    { label: "Action", align: "left" },
  ],
};

export const bookingData = {
  headerDetails: [
    // { label: "S. No" },
    // { label: "Order Id", align: "left" },
    // { label: "Booking Type", align: "left" },
    // { label: "User Name", align: "left" },
    // { label: "Packages/Test Name" },
    // { label: "Test Included" },
    // { label: "Appointment Date", align: "left" },
    // { label: "Amount" },
    // { label: "Status", align: "left" },
    // { label: "Payment Type", align: "left" },
    // { label: "Action" },

    { label: "S. No" },
    { label: "Order Id", align: "left" },
    { label: "User Name", align: "left" },
    { label: "Packages/Test Name" },
    { label: "Appointment", align: "left" },
    { label: "Amount" },
    { label: "Payment Status", align: "left" },
    { label: "Order Status", align: "left" },
    { label: "Payment Type", align: "left" },
    { label: "Action" },
  ],
};

export const cityData = {
  headerDetails: [
    { label: "S. No" },
    { label: "Name", align: "left" },
    { label: "Slug", align: "left" },
    { label: "State", align: "left" },
    { label: "Action", align: "left" },
  ],
};

export const centerLocationsData = {
  headerDetails: [
    { label: "S. No" },
    { label: "Center Name", align: "left" },
    { label: "State", align: "left" },
    { label: "Action", align: "left" },
  ],
};

export const userData = {
  headerDetails: [
    { label: "S. No" },
    { label: "Name", align: "left" },
    { label: "Mobile Number", align: "left" },
    { label: "Email", align: "left" },
    { label: "Action", align: "left" },
  ],
};


export const userDataEnquires = {
  headerDetails: [
    { label: "S. No" },
    { label: "Name", align: "left" },
    { label: "Mobile Number", align: "left" },
    { label: "State", align: "left" },
    { label: "City", align: "left" },
    { label: "Date", align: "left" },
    { label: "Action", align: "left" },
  ],
};

export const newsLetterData = {
  headerDetails: [
    { label: "S. No" },
    { label: "Title", align: "left" },
    { label: "Status", align: "left" },
    { label: "Status Action", align: "left" },
    { label: "Action", align: "left" },
  ],
};

export const paymentHistoriesTblData = {
  headerDetails: [
    { label: "S. No" },
    { label: "Amount" },
    //{ label: "Payment Status" },
    { label: "Transaction Id" },
    { label: "PG Internal Order Id" },
    { label: "State" },
    { label: "Created At" },
  ],
};

export const graphNoData = {
  lang: { noData: "No data found" },
  noData: { style: { fontWeight: "bold", fontSize: "16px" } },
};

export const passwordHintData = [
  { name: "UpperCase", status: false },
  { name: "LowerCase", status: false },
  { name: "Number", status: false },
  { name: "Special", status: false },
];