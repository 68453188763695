import { history } from "helpers";
import { getIpAddress, getToken } from "helpers/utis";
import PropTypes from "prop-types";
import { useCallback, useEffect } from "react";
import "./authlayout.scss";

export function AuthLayout(props) {
  const { children } = props;

  const websiteGuard = useCallback(() => {
    if (getToken()) history.push("/dashboard/home");
  }, []);

  useEffect(() => {
    websiteGuard();
  });

  useEffect(() => {
    if (!localStorage.getItem("ip")) {
      getIpAddress();
    }
  }, []);

  return <div className="auth-content-wrapper">{children}</div>;
}

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
