import PropTypes from "prop-types";

import AdminNavbar from "components/Navbar";

import "./mainlayout.scss";
import { Sidebar } from "components/common/Sidebar";
import { useCallback, useEffect, useState } from "react";
import { getIpAddress, getToken } from "helpers/utis";
import { logout } from "service/utilities";

export function MainLayout(props) {
  const { children } = props;

  const [isOpen, setOpen] = useState(true);

  const toggle = () => setOpen(!isOpen);

  const websiteGuard = useCallback(() => {
    if (!getToken()) logout();
  }, []);

  useEffect(() => {
    websiteGuard();
  });

  useEffect(() => {
    if (!localStorage.getItem("ip")) {
      getIpAddress();
    }
  }, []);

  return (
    <div className={`main-layout container-fluid`}>
      <Sidebar toggle={toggle} isOpen={isOpen} />
      <div className="main-layout-wrapper">
        <AdminNavbar isOpen={isOpen} />
        <div
          className={`main-content-wrapper ${
            isOpen ? "open-layout" : "closed-layout"
          }`}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
