import { AuthApi } from "service/apiVariables";

// login
export const login =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...AuthApi.loginApi, body })
        .then((data) => {
          Toast({ type: "success", message: data.message });
          localStorage.setItem("adminDetails", JSON.stringify(data.data));
          localStorage.setItem("adminToken", data.data?.token);
          resolve(data);
        })
        .catch(({ message }) => reject(Toast({ type: "error", message })));
    });
  };

// forgot password
export const forgotPassword =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...AuthApi.forgotPasswordApi, body })
        .then((data) => {
          Toast({ type: "success", message: data.message });
          resolve(data);
        })
        .catch(({ message }) => reject(Toast({ type: "error", message })));
    });
  };

//reset Password
export const resetPasswordAct =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...AuthApi.resetPasswordApi, body })
        .then((data) => {
          Toast({ type: "success", message: data.message });
          resolve(data);
          localStorage.removeItem("resetToken");
        })
        .catch(({ message }) => reject(Toast({ type: "error", message })));
    });
  };

// logout
export const logoutAct =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...AuthApi.logoutApi, body })
        .then((data) => {
          Toast({ type: "success", message: data.message });
          resolve(data);
        })
        .catch(({ message }) => reject(Toast({ type: "error", message })));
    });
  };

export const changePasswordAct =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...AuthApi.changePasswordApi, body })
        .then((data) => {
          Toast({ type: "success", message: data.message });
          resolve(data);
        })
        .catch(({ message }) => reject(Toast({ type: "error", message })));
    });
  };
