import { Navbar,Dropdown, DropdownButton } from "react-bootstrap";
import PropTypes from "prop-types";
import UserIcon from "assets/images/svg/user-icon.svg";
import { logout } from "service/utilities";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import "./navbar.scss";
import { logoutAct } from "redux/actions/authActions";

/**
 * Navbar: The common re-usable navbar component
 * @return {JSX.Element} The JSX Code for the Home Page
 */

function AdminNavbar({ isOpen, logoutApi }) {
  const handleLogout = () => {
    logoutApi().then(() => logout());
  };
  return (
    <div className="navbar pb-0">
      <Navbar
        expand="lg"
        variant="light"
        bg="light"
        fixed="top"
        className={`admin-navbar ${isOpen ? "open-sidebar" : "closed-sidebar"}`}
      >
        <div
          className="d-flex align-items-center ms-auto navbar-user-info fs-14 cursor-pointer"
        >
          <img src={UserIcon} alt="Admin User" />
          <DropdownButton
            title="Admin User" 
            variant="light"
          > 
            <Dropdown.Item onClick={() => handleLogout()}>Logout</Dropdown.Item>
          </DropdownButton>
          {/* <button type="button" className="btn p-0 no-style d-flex mr-50">
            <i className="icon-bell fs-24" />
          </button>
          <button type="button" className="btn p-0 no-style d-flex mr-50">
            <i className="icon-setting fs-24" />
          </button> */}
        </div>
      </Navbar>
    </div>
  );
}

AdminNavbar.propTypes = {
  isOpen: PropTypes.bool,
};

AdminNavbar.defaultProps = {
  isOpen: true,
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ logoutApi: logoutAct }, dispatch);
};

export default connect(null, mapDispatchToProps)(AdminNavbar);
