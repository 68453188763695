import { getToken } from "helpers/utis";
import {
  axiosInstance,
  cancelTokenSource,
  statusHelper,
  getServiceUrl,
} from "./utilities";

export const api = async function ({
  method = "get",
  api: apiURL,
  body,
  status = false,
  baseURL = "user",
  configObj = {},
}) {
  const tempConfigObj = { ...configObj };

  if (cancelTokenSource.token)
    tempConfigObj.cancelToken = cancelTokenSource.token;

  const getValidToken = () => {
    let token = getToken();
    let resetToken = localStorage.getItem("resetToken");
    if (resetToken) {
      axiosInstance.defaults.headers.common.Authorization = `Bearer ${resetToken}`;
    } else if (token) {
      axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
    }
  };

  return await new Promise((resolve, reject) => {
    getValidToken();

    axiosInstance[method](
      `${getServiceUrl(baseURL)}${apiURL}`,
      body || "",
      tempConfigObj
    )
      .then((data) => resolve(statusHelper(status, data)))
      .catch((error) => {
        try {
          if (error.response) {
            reject(statusHelper(status, error.response));
          } else {
            reject(error);
          }
        } catch (err) {
          reject(err);
        }
      });
  });
};
