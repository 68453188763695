import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Logo from "assets/images/svg/logo_greenbg.svg";

import "./sidebar.scss";
import { menuItems } from "Data/staticDatas";
import { ListItem } from "./ListItem";

/**
 * Sidebar: The common re-usable sidebar componentt
 * @return {JSX.Element} The JSX Code for the Sidebar component
 */

export function Sidebar({ toggle, isOpen }) {
  return (
    <div
      className={`custom-sidebar ${isOpen ? "open-sidebar" : "closed-sidebar"}`}
    >
      <div className="menu-container">
        {/* <button
          type="button"
          className="btn p-0 no-style d-flex"
          onClick={toggle}
        >
          <i className="icon-menu fs-24" />
        </button> */}
        <Link to="/dashboard/home" className="ml-30 sidebar-logo">
          <img src={Logo} alt="Brand Logo" />
        </Link>
      </div>
      <div className="menu-sidebar">
        <ul className="items-container">
          {menuItems?.map((data) => (
            <ListItem {...data} key={data.title} />
          ))}
        </ul>
      </div>
    </div>
  );
}

Sidebar.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
};

Sidebar.defaultProps = {
  toggle: () => null,
  isOpen: true,
};
