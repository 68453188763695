const config = {
  development: {
    baseUrl: process.env.REACT_APP_URL,
    api: {
      userService: process.env.REACT_APP_USER_SERVICE_BASE_URL,
      paymentService: process.env.REACT_APP_PAYMENT_SERVICE_BASE_URL,
      cmsService: process.env.REACT_APP_CMS_SERVICE_BASE_URL,
      bookingService: process.env.REACT_APP_BOOKING_SERVICE_BASE_URL,
    },
  },
  staging: {
    baseUrl: process.env.REACT_APP_URL,
    api: {
      userService: process.env.REACT_APP_USER_SERVICE_BASE_URL,
      paymentService: process.env.REACT_APP_PAYMENT_SERVICE_BASE_URL,
      cmsService: process.env.REACT_APP_CMS_SERVICE_BASE_URL,
      bookingService: process.env.REACT_APP_BOOKING_SERVICE_BASE_URL,
    },
  },
  production: {
    baseUrl: "https://admin.manipaltrutest.com",
    api: {
      userService: "https://users.manipaltrutest.com/api/v1",
      paymentService: "https://payment.manipaltrutest.com/api/v1",
      cmsService: "https://cms.manipaltrutest.com/api/v1",
      bookingService: "https://booking.manipaltrutest.com/api/v1",
    },
  },
};

let configBasedOnEnv = {};

const env = process.env.NEXT_PUBLIC_ENV_VARIABLE;

configBasedOnEnv = config[env] ? config[env] : config.development;

export default configBasedOnEnv;
