import axios from "axios";

import config from "../config";
import { Toast } from "./toast";

export const axiosInstance = axios.create({
  headers: {},
});

export const cancelTokenSource = axios.CancelToken.source();

export const setLocalStorageData = (key, value) => {
  localStorage.setItem(key, value);
};

export const getLocalStorageData = (key) => {
  if (!key) return null;
  return JSON.parse(localStorage.getItem(key));
};

export const logout = () => {
  axiosInstance.defaults.headers.common.Authorization = "";
  localStorage.removeItem("adminDetails");
  localStorage.removeItem("adminToken");
  setTimeout(() => {
    window.location.pathname = "/";
  }, 200);
};

export const getServiceUrl = (baseURL) => {
  let finalURL = "";

  switch (baseURL) {
    case "user":
      finalURL = config.api.userService;
      break;
    case "payment":
      finalURL = config.api.paymentService;
      break;
    case "cms":
      finalURL = config.api.cmsService;
      break;
    case "booking":
      finalURL = config.api.bookingService;
      break;
    default:
      finalURL = config.api.userService;
  }

  return finalURL;
};

export const statusHelper = (status, data) => {
  if (data.status === 401 && cancelTokenSource) cancelTokenSource.cancel();

  if (data.status === 401 || data.status === 403) {
    Toast({ type: "error", message: data.statusText });
    setTimeout(() => logout(), 1000);
  }
  if (status) {
    return { status: data.status, ...data.data };
  }
  return data.data;
};

export const isAuthorized = async () => {
  if (
    localStorage.getItem("adminToken") !== null &&
    localStorage.getItem("adminToken") !== undefined
  ) {
    return true;
  }
  return false;
};
